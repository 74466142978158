.clearfix:after {
  content: '';
  display: table-cell;
  clear: both;
}

.clearfix {
  *zoom: 1;
}

/* 公共样式 */

/* 禁用元素点击事件 */

.eventsDisabled {
  pointer-events: none;
  cursor: pointer;
}

/* common */

html,
body {
  height: 100%;
  -webkit-user-select: none;
  user-select: none;
  --window-top: 0px;
  --window-bottom: 0px;
  --status-bar-height: 0px;
}

body {
  font-family: "HiraginoSansGB-W3";
  /* font-size: 0.14rem; */
  font-size: 16px;
  display: block;
  /* 让body bfc */
  width: 100%;
  background-color: #fff;
}

a {
  text-decoration: none;
}

.vh100 {
  height: 100vh;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.h100scroll {
  /* 固定在页面下面的div不能放在这个容器中 */
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.img100 {
  width: 100%;
  height: 100%;
}

.imgw100 {
  width: 100%;
  height: auto;
}

.imgh100 {
  width: auto;
  height: 100%;
}

.overhidden {
  overflow: hidden;
}

.marginlr14 {
  margin-left: 0.14rem;
  margin-right: 0.14rem;
}

.paddinglr14 {
  padding-left: 0.14rem;
  padding-right: 0.14rem;
}

.flex {
  display: flex;
}

.flexCenter {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -webkit-box-align: center;
  -moz-align-items: center;
  -webkit-align-items: center;
}

.flexBetween {
  justify-content: space-between;
  -webkit-box-pack: space-between;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
}

.flexAround {
  justify-content: space-around;
  -webkit-box-pack: space-around;
  -moz-justify-content: space-around;
  -webkit-justify-content: space-around;
}

.flexJCenter {
  justify-content: center;
  -webkit-box-pack: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -o-justify-content: center;
}

.flexCenterIL {
  display: inline-flex;
  align-items: center;
}

.flexColumn {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -webkit-box-align: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  flex-direction: column
}

.posiRe {
  position: relative;
}

.positionCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 省略三个点 */

.textOmit {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 限制行数 */

.limitLine2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.limitLine3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.limitLine4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.limitLine5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

/* ///////////////////////////////////////////////////////////////////// */

/* xs 576px sm 768px md 992px lg 1200px xl */

a,
a:active,
a:focus,
a:visited {
  color: #000;
  text-decoration: none;
}

body {
  min-width: 1080px;
  font-family: "微软雅黑";
  background-color: #EEEEEE;
}

.common_wrap {
  /* width: 1200px; */
  width: 1048px;
  margin: 0 auto;
}

.page_container {
  /* width: 1200px; */
  margin: 30px auto;
  width: 1048px;
  background-color: #fff;
  box-sizing: border-box;
  padding-top: 25px;
  padding-bottom: 25px;
}

/* 字体 */
@media screen and (min-width:1920px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width:1679) and (max-width:1919px) {
  html {
    font-size: 15px;
  }
}

@media screen and (min-width:1377) and (max-width:1678px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width:1024) and (max-width:1376px) {
  html {
    font-size: 13px;
  }
}

@media screen and (min-width:800) and (max-width:1023px) {
  html {
    font-size: 12px;
  }
}