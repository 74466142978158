body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.successCase {
  background-color: #fff;
  padding-bottom: 40px;
}
.successCase .range {
  height: 12px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABLkAAAAPCAYAAAAI5x22AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAJhJREFUeNrs3LENgEAMBEGeHojpnyb9fBkrzUhu4MINvGbmAgAAAICy2wQAAAAA1IlcAAAAAOSJXAAAAADkiVwAAAAA5IlcAAAAAOSt/x4zAAAAAFB2ItdnBgAAAADKTuR6zQAAAABA2ZoZKwAAAACQ5vE8AAAAAHkiFwAAAAB5IhcAAAAAeSIXAAAAAHkiFwAAAAB5W4ABAMrOBvL46hgoAAAAAElFTkSuQmCC) center center no-repeat;
  background-size: 100% 100%;
}
.successCase .range .dian {
  width: 12px;
  height: 12px;
  transition: all 0.3s ease-in;
}
.successCase .range .dian.active {
  border-radius: 50%;
  border: 1px solid #000;
  background-color: #f66f6a;
}
.successCase .year_wrap {
  margin-top: 16px;
}
.successCase .year_wrap .year {
  color: #9B9B9B;
  cursor: pointer;
  font-size: 35px;
  font-weight: bold;
  transition: all 0.3s ease-in;
}
.successCase .year_wrap .year.active {
  color: #F66F6A;
}
.successCase .descp_wrap {
  font-size: 14px;
  color: #000;
  margin-top: 35px;
  line-height: 27px;
}

.home_titlewrap {
  margin-top: 35px;
  margin-bottom: 20px;
}
.home_titlewrap .home_titlewrap_title {
  font-size: 21px;
  color: #000;
}
.home_titlewrap .home_titlewrap_descp {
  font-size: 12px;
  line-height: 32px;
}
.item {
  border: 1px solid #eaeaea;
  width: calc(33.3333% - 20px);
  margin: 10px 10px;
  padding: 50px 0;
  position: relative;
}
.item img {
  width: auto;
  height: 62px;
  margin-bottom: 10px;
}
.item .title {
  font-size: 16px;
}
.item .tip {
  color: #4a4a4a;
  font-size: 12px;
  width: 70%;
}
.home_page .common_item_wrap {
  padding-bottom: 30px;
}
.home_page .ant-carousel {
  font-size: 0;
}
.home_page .carousel_item {
  height: 0;
  padding-top: 30%;
  position: relative;
  overflow: hidden;
  min-height: 412px;
}
.home_page .carousel_item .img_bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.home_page .carousel_item .carousel_content {
  color: #fff;
  text-align: center;
}
.home_page .carousel_item .carousel_content .carousel_title {
  font-size: 52px;
  font-weight: 100;
}
.home_page .carousel_item .carousel_content .carousel_descp {
  font-size: 22px;
}
.home_page .bottomTextWrap {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 87px;
  background-color: rgba(255, 255, 255, 0.1);
}
.home_page .downapp {
  padding-bottom: 70px;
}
.home_page .downapp .app_wrap {
  margin-top: 35px;
}
.home_page .downapp .app_wrap .downappitem {
  width: 94px;
  margin: 0 55px;
}
.home_page .downapp .appcenter {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top: 30px;
  margin-left: 400px;
  box-shadow: 0 0 20px 2px #cfcfcf;
}
.home_page .downapp .appcenter img {
  width: 140px;
  height: 140px;
}
.home_page .partner {
  padding-bottom: 110px;
}
.home_page .partner .partner_wrap {
  margin-top: 30px;
}
.home_page .partner .partner_wrap .parter_item_wrap {
  width: calc(20% - 20px);
  margin: 10px 10px;
  transition: all 0.2s ease-in-out;
}
.home_page .partner .partner_wrap .parter_item_wrap:hover {
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  transform: translate3d(0, -2px, 0);
}
.home_page .partner .partner_wrap .partner_item {
  box-sizing: border-box;
  background-color: #fff;
  width: 194px;
  height: 61px;
  position: relative;
}
@media (max-width: 1376px) {
  .home_page .carousel_item .carousel_content .carousel_title {
    font-size: 40px;
    font-weight: 100;
  }
  .home_page .carousel_item .carousel_content .carousel_descp {
    font-size: 19px;
  }
  .home_page .bottomTextWrap {
    height: 75px;
  }
}

.page_bg {
  height: 0;
  padding-top: 30%;
  position: relative;
}
.page_bg .img_wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.page_bg .content {
  color: #fff;
  text-align: center;
  top: calc(50% - 34px);
}
.page_bg .content .title {
  font-size: 52px;
  font-weight: 100;
}
.page_bg .content .descp {
  font-size: 22px;
}
.page_bg .bottomTextWrap {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 87px;
  background-color: rgba(255, 255, 255, 0.1);
}
@media (max-width: 1376px) {
  .page_bg .content .title {
    font-size: 40px;
    font-weight: 100;
  }
  .page_bg .content .descp {
    font-size: 19px;
  }
  .page_bg .bottomTextWrap {
    height: 75px;
  }
}

.app_item {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 100%;
  position: relative;
}
.app_item .imgBox {
  height: 0;
  padding-top: 100%;
  position: relative;
}
.app_item .imgBox img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.app_item .qrCode {
  background-color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-color: #fff;
  box-shadow: 0 0 10px 2px #d4d4d4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app_item .qrCode img {
  width: 79%;
  height: 79%;
}

.case {
  background-color: #EEEEEE;
  color: rgba(0, 0, 0, 0.8);
}
.case .page_container .case_app_item_wrap .case_app_item {
  display: flex;
  align-items: flex-start;
  border: 1px solid #DBDBDB;
  width: 90%;
  margin: 20px auto;
  padding: 25px 36px;
}
.case .page_container .case_app_item_wrap .case_app_item .imgBox {
  width: 87px;
  height: 87px;
}
.case .page_container .case_app_item_wrap .case_app_item .case_content {
  flex: 1 0;
  margin-left: 20px;
  padding-top: 10px;
}
.case .page_container .case_app_item_wrap .case_app_item .qrCode_box {
  align-self: flex-end;
}
.case .page_container .case_app_item_wrap .case_app_item .qrCode_box .qrCode_img_box {
  width: 87px;
  height: 87px;
  border: 1px solid #979797;
}
.case .page_container .case_app_item_wrap .case_app_item .qrCode_box .qrCode_text {
  color: #4A4A4A;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
}
.case .page_container .appIcon_wrap {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  position: relative;
  padding-top: 33px;
  padding-bottom: 10px;
}
.case .page_container .appIcon_wrap .appIcon_item {
  width: 70px;
  margin-bottom: 70px;
  transition: all 0.15s ease-in;
}
.case .page_container .appIcon_wrap .appIcon_item.active {
  transform: scale(1.1) !important;
}

.newretail_page {
  background-color: #EEEEEE;
}
.newretail_page .common_item_wrap {
  padding-bottom: 30px;
}
.newretail_page .newretail_titlewrap {
  margin-top: 50px;
  margin-bottom: 40px;
}
.newretail_page .newretail_titlewrap .newretail_titlewrap_title {
  font-size: 24px;
  color: #000;
}
.newretail_page .newretail_titlewrap .newretail_titlewrap_descp {
  font-size: 14px;
  line-height: 32px;
}
.newretail_page .contact_container {
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 30px;
}
.newretail_page .guanxi_wrap {
  background: url(../../static/media/guanxi_bg.8dbfbdae.png) center center no-repeat;
  background-size: 100% 100%;
}
.newretail_page .guanxi_wrap .guanxi_container {
  box-sizing: border-box;
  height: 100%;
  width: 360px;
  padding-top: 50px;
}
.newretail_page .guanxi_wrap .step1,
.newretail_page .guanxi_wrap .step2,
.newretail_page .guanxi_wrap .step3 {
  color: rgba(255, 255, 255, 0.8);
}
.newretail_page .guanxi_wrap .step1 {
  height: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.newretail_page .guanxi_wrap .step2 {
  height: 73px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.newretail_page .guanxi_wrap .step3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-bottom: 15px;
}
.newretail_page .zhArrow {
  width: 84px;
}
.newretail_page .zhItem {
  border: 1px solid #4a4a4a;
  width: 121px;
  height: 50px;
  color: #4a4a4a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.lexin_page .common_item_wrap {
  padding-bottom: 30px;
}
.lexin_page .lexin_titlewrap {
  margin-top: 50px;
  margin-bottom: 40px;
}
.lexin_page .lexin_titlewrap .lexin_titlewrap_title {
  font-size: 24px;
  color: #000;
}
.lexin_page .lexin_titlewrap .lexin_titlewrap_descp {
  font-size: 14px;
  line-height: 32px;
}
.lexin_page .allSpanWrap {
  padding: 0 250px;
  position: relative;
  top: -128px;
}
.lexin_page .allSpanWrap .blueSpan,
.lexin_page .allSpanWrap .greenSpan,
.lexin_page .allSpanWrap .orangeSpan {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  overflow: hidden;
}
.lexin_page .allSpanWrap .blueSpan {
  background-color: #197CD5;
}
.lexin_page .allSpanWrap .greenSpan {
  background-color: #00B57C;
}
.lexin_page .allSpanWrap .orangeSpan {
  background-color: #FF5900;
}
.lexin_page .whyItem {
  width: 472px;
  height: 157px;
  background-color: #fff;
  margin: 15px 10px;
  transition: all 0.2s ease-in-out;
}
.lexin_page .whyItem.active {
  position: relative;
  top: -3px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
}
.lexin_page .yuanWrap .yuan {
  transform-origin: center;
}
.lexin_page .yuanWrap .yuan1 {
  -webkit-animation: zhuan 21s linear infinite;
  animation: zhuan 21s linear infinite;
}
.lexin_page .yuanWrap .yuan2 {
  -webkit-animation: zhuan 23s linear infinite;
  animation: zhuan 23s linear infinite;
}
.lexin_page .yuanWrap .yuan3 {
  -webkit-animation: zhuan 25s linear infinite;
  animation: zhuan 25s linear infinite;
}
.lexin_page .yuanWrap .yuan4 {
  -webkit-animation: zhuan 27s linear infinite;
  animation: zhuan 27s linear infinite;
}
.lexin_page .yuanWrap .yuan5 {
  -webkit-animation: zhuan 29s linear infinite;
  animation: zhuan 29s linear infinite;
}
.lexin_page .yuanWrap .yuan6 {
  -webkit-animation: zhuan 31s linear infinite;
  animation: zhuan 31s linear infinite;
}
@keyframes zhuan {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  25% {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(180deg);
  }
  75% {
    transform: translate(-50%, -50%) rotate(270deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes zhuan {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(-50%, -50%) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
  }
}
.lexin_page .fanganItem {
  color: #fff;
  height: 110px;
  padding: 0 5px;
  margin-top: 44px;
}
.lexin_page .fanganItem .fanganTitle {
  font-size: 16px;
  margin-bottom: 5px;
}
.lexin_page .fanganItem .fanganTips {
  font-size: 12px;
}
.lexin_page .zhaunshuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 367px;
  margin: 0 10px;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.lexin_page .zhaunshuItem::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}
.lexin_page .zhaunshuItem .zhuanshuImgBox,
.lexin_page .zhaunshuItem .zhuanshuTitleWrap,
.lexin_page .zhaunshuItem .zhuanshuDescp {
  position: relative;
  z-index: 99;
  transition: all 0.3s ease-in-out;
}
.lexin_page .zhaunshuItem .zhuanshuDescp {
  width: 151px;
  height: 0;
  margin-top: 0;
  overflow: hidden;
}
.lexin_page .zhaunshuItem.active {
  background-color: #5389C8 !important;
  transform: scale(1.1);
}
.lexin_page .zhaunshuItem.active::after {
  background-image: linear-gradient(180deg, rgba(132, 173, 248, 0.6) 0, #556ae3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#84ADF899, endColorstr=#556AE3);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#84ADF899, endColorstr=#556AE3)";
}
.lexin_page .zhaunshuItem.active .zhuanshuDescp {
  height: 140px;
  margin-top: 38px;
}

.aboutus_page {
  background-color: #EEEEEE;
}
.aboutus_page .aboutus_titlewrap {
  margin-top: 50px;
  margin-bottom: 40px;
}
.aboutus_page .aboutus_titlewrap .aboutus_titlewrap_title {
  font-size: 24px;
  color: #000;
}
.aboutus_page .contact_container {
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 30px;
}
.aboutus_page .address {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid #D7D6D6;
}
.aboutus_page .address .address_descp {
  color: #4A4A4A;
  font-size: 14px;
  margin-left: 35px;
  line-height: 30px;
}
.aboutus_page .address .address_descp .title {
  font-size: 24px;
  letter-spacing: 1px;
  margin: 10px 0 40px;
}
.aboutus_page .address .amap-logo,
.aboutus_page .address .amap-copyright {
  display: none !important;
}
.aboutus_page .msg_container {
  padding: 30px 0 45px;
  box-sizing: border-box;
  border: 1px solid #D7D6D6;
}
.aboutus_page .msg_container .ant-select-show-search ant-select-auto-complete ant-select ant-select-combobox ant-select-enabled,
.aboutus_page .msg_container input.ant-input {
  width: 240px;
}
.aboutus_page .msg_container textarea.ant-input {
  min-height: 166px;
  font-size: 12px;
  padding: 15px 10px;
}
.aboutus_page .msg_container .ant-btn {
  background-color: #F66F6A;
  color: #fff;
  display: block;
  width: 104px;
  height: 38px;
  margin: 0 auto;
  font-size: 12px;
  margin-top: 20px;
}
.aboutus_page .msg_container .ant-btn:hover,
.aboutus_page .msg_container .ant-btn:focus {
  color: #fff;
  border-color: #F66F6A;
}

.joinus {
  background-color: #EEEEEE;
}
.joinus .page_container {
  padding: 48px 75px 70px;
  min-height: 500px;
  font-size: 14px;
}
.joinus .page_container .tab_wrap {
  border-bottom: 1px solid #D8D8D8;
  line-height: 45px;
  padding: 0 20px;
}
.joinus .page_container .tab_wrap .tab_item {
  color: #4A4A4A;
  cursor: pointer;
  padding: 0 10px;
  box-sizing: border-box;
}
.joinus .page_container .tab_wrap .tab_item.active {
  color: #F66F6A;
  border-bottom: 3px solid #F66F6A;
}
.joinus .page_container .job_wrap {
  color: #4A4A4A;
  padding-top: 30px;
}

.carousel3d {
  position: relative;
}
.carousel3d .wrap {
  position: relative;
  width: 85%;
  max-width: 796px;
}
.carousel3d .wrap .cItem {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
}
.carousel3d .wrap .cItem .imgWrap {
  display: flex;
  width: 220px;
  height: 300px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.carousel3d .wrap .cItem .imgWrap img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.carousel3d .wrap .cItem .p1 {
  transform: translate3d(-280px, 0, 0) scale(0.6705);
  z-index: 1;
  opacity: 0.76;
}
.carousel3d .wrap .cItem .p2 {
  transform: translate3d(-210px, 0, 0) scale(0.8011);
  z-index: 2;
  opacity: 0.82;
}
.carousel3d .wrap .cItem .p3 {
  transform: translate3d(-140px, 0, 0) scale(0.8693);
  z-index: 3;
  opacity: 0.88;
}
.carousel3d .wrap .cItem .p4 {
  transform: translate3d(-70px, 0, 0) scale(0.9318);
  z-index: 4;
  opacity: 0.94;
}
.carousel3d .wrap .cItem .p5 {
  transform: translate3d(0, 0, 0) scale(1);
  z-index: 5;
  opacity: 1;
}
.carousel3d .wrap .cItem .p6 {
  transform: translate3d(70px, 0, 0) scale(0.9318);
  z-index: 4;
  opacity: 0.94;
}
.carousel3d .wrap .cItem .p7 {
  transform: translate3d(140px, 0, 0) scale(0.8693);
  z-index: 3;
  opacity: 0.88;
}
.carousel3d .wrap .cItem .p8 {
  transform: translate3d(210px, 0, 0) scale(0.8011);
  z-index: 2;
  opacity: 0.82;
}
.carousel3d .wrap .cItem .p9 {
  transform: translate3d(280px, 0, 0) scale(0.6705);
  z-index: 1;
  opacity: 0.76;
}
.carousel3d .carousel3d_leftBtn,
.carousel3d .carousel3d_rightBtn {
  min-height: 220px;
  cursor: pointer;
  width: 40px;
  z-index: 10;
}
.carousel3d .carousel3d_leftBtn:hover,
.carousel3d .carousel3d_rightBtn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.qualification {
  background-color: #EEEEEE;
}
.qualification .page_container {
  padding: 5px 20px 55px;
}
.qualification .page_container img {
  box-sizing: border-box;
  cursor: pointer;
}
.qualification .page_container .qualification_title {
  color: #4A4A4A;
  text-align: center;
  margin: 70px 0 45px;
  font-size: 21px;
}
.qualification .page_container .qualification_title::before,
.qualification .page_container .qualification_title::after {
  content: "";
  width: 40%;
  border-top: 1px #D8D8D8 solid;
  display: inline-block;
  vertical-align: middle;
}
.qualification .page_container .qualification_title::before {
  margin-right: 10px;
}
.qualification .page_container .qualification_title::after {
  margin-left: 10px;
}
.qualification .page_container .xuke_wrap .xuke_item {
  margin: 0 8px;
}
.qualification .page_container .xuke_wrap .xuke_item img {
  width: auto;
  border: 1px solid #ebebeb;
}
.qualification .page_container .zhuanli_wrap {
  overflow: hidden;
  width: 85%;
  max-width: 850px;
  position: relative;
}
.qualification .page_container .zhuanli_wrap .zhuanli_item_wrap {
  position: absolute;
  top: 0;
  left: 0;
  transition: left 0.2s;
}
.qualification .page_container .zhuanli_wrap .zhuanli_item_wrap .zhuanli_item {
  float: left;
}
.qualification .page_container .zhuanli_wrap .zhuanli_item_wrap .zhuanli_item img {
  width: 92%;
  height: auto;
  height: 100%\0;
}
.qualification .page_container .leftBtn,
.qualification .page_container .rightBtn {
  min-height: 200px;
  cursor: pointer;
  width: 40px;
  margin-top: -90px;
}
.qualification .page_container .leftBtn:hover,
.qualification .page_container .rightBtn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.anticon.anticon-right,
.anticon.anticon-left {
  position: absolute\0;
  top: 50%\0;
  left: 50%\0;
  transform: translate3d(-50%, -50%, 0) \0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding-top: 28px;
  padding-bottom: 15px;
}
.header .header_logo {
  height: 31px;
}
.header .link_wrap a {
  display: block;
  margin: 0 11px;
}
.header .link_wrap a::after {
  content: "";
  display: block;
  width: 0;
  margin: 0 auto;
  height: 2px;
  transition: width 0.1s ease-in;
}
.header .link_wrap a.active::after {
  width: 100%;
}
.header .link_wrap a.white {
  color: #fff;
}
.header .link_wrap a.white::after {
  background-color: #fff;
}
.header .link_wrap a.black {
  color: #000;
}
.header .link_wrap a.black::after {
  background-color: #000;
}

.footer {
  background-color: #252B3A;
  color: #fff;
  height: 60px;
  display: flex;
  flex-direction: column;
}
.footer .container_wrap {
  width: 70%;
  min-width: 850px;
  margin: 0 auto;
  flex: 1 0;
  align-items: flex-start;
  padding-top: 62px;
}
.footer .container_wrap .container {
  letter-spacing: 1px;
  line-height: 30px;
}
.footer .container_wrap .container .title {
  font-size: 16px;
}
.footer .container_wrap .container .container_content {
  font-size: 12px;
}
.footer .container_wrap .container .container_content a {
  display: block;
  color: #fff;
}
.footer .container_wrap .qrCode {
  width: 120px;
  height: 120px;
}
.footer .footer_bot {
  height: 65px;
  border-top: 1px solid #D8D8D8;
  font-size: 12px;
  letter-spacing: 1px;
}

.clearfix:after {
  content: '';
  display: table-cell;
  clear: both;
}

.clearfix {
  *zoom: 1;
}

/* 公共样式 */

/* 禁用元素点击事件 */

.eventsDisabled {
  pointer-events: none;
  cursor: pointer;
}

/* common */

html,
body {
  height: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  --window-top: 0px;
  --window-bottom: 0px;
  --status-bar-height: 0px;
}

body {
  font-family: "HiraginoSansGB-W3";
  /* font-size: 0.14rem; */
  font-size: 16px;
  display: block;
  /* 让body bfc */
  width: 100%;
  background-color: #fff;
}

a {
  text-decoration: none;
}

.vh100 {
  height: 100vh;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.h100scroll {
  /* 固定在页面下面的div不能放在这个容器中 */
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);
}

.img100 {
  width: 100%;
  height: 100%;
}

.imgw100 {
  width: 100%;
  height: auto;
}

.imgh100 {
  width: auto;
  height: 100%;
}

.overhidden {
  overflow: hidden;
}

.marginlr14 {
  margin-left: 0.14rem;
  margin-right: 0.14rem;
}

.paddinglr14 {
  padding-left: 0.14rem;
  padding-right: 0.14rem;
}

.flex {
  display: flex;
}

.flexCenter {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-box-align: center;
  -moz-align-items: center;
  -webkit-align-items: center;
}

.flexBetween {
  justify-content: space-between;
  -webkit-box-pack: space-between;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
}

.flexAround {
  justify-content: space-around;
  -webkit-box-pack: space-around;
  -moz-justify-content: space-around;
  -webkit-justify-content: space-around;
}

.flexJCenter {
  justify-content: center;
  -webkit-box-pack: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -o-justify-content: center;
}

.flexCenterIL {
  display: inline-flex;
  align-items: center;
}

.flexColumn {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-box-align: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  flex-direction: column
}

.posiRe {
  position: relative;
}

.positionCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 省略三个点 */

.textOmit {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 限制行数 */

.limitLine2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.limitLine3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.limitLine4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.limitLine5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

/* ///////////////////////////////////////////////////////////////////// */

/* xs 576px sm 768px md 992px lg 1200px xl */

a,
a:active,
a:focus,
a:visited {
  color: #000;
  text-decoration: none;
}

body {
  min-width: 1080px;
  font-family: "\5FAE\8F6F\96C5\9ED1";
  background-color: #EEEEEE;
}

.common_wrap {
  /* width: 1200px; */
  width: 1048px;
  margin: 0 auto;
}

.page_container {
  /* width: 1200px; */
  margin: 30px auto;
  width: 1048px;
  background-color: #fff;
  box-sizing: border-box;
  padding-top: 25px;
  padding-bottom: 25px;
}

/* 字体 */
@media screen and (min-width:1920px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width:1679) and (max-width:1919px) {
  html {
    font-size: 15px;
  }
}

@media screen and (min-width:1377) and (max-width:1678px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width:1024) and (max-width:1376px) {
  html {
    font-size: 13px;
  }
}

@media screen and (min-width:800) and (max-width:1023px) {
  html {
    font-size: 12px;
  }
}
